@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

// @import 'fonts';

@import "_borders";

@import "__ads";

@import "__splash-ad.scss";

@import "__dho-features";

/* remove blockquote defaults */
blockquote {
    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

// button:disabled {
//     @apply opacity-50;
// }

div.embed > iframe {
    max-width: 100vw;
}

/* purgecss start ignore */
/* remove focus outline */
a:focus,
button:focus {
    @apply outline-none;
}
/* purgecss end ignore */

/* begin hide scrollbars */
.hide-scrollbars {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        background: transparent; /* Chrome/Safari/Webkit */
    }
}
/* end hide scrollbars */

.bogus {
    display: none;
}

.thaana {
    direction: rtl;
    -webkit-font-smoothing: antialiased;
}

.text-waheed {
    @apply font-waheed;
    @apply thaana;
}

.text-faseyha {
    @apply font-faseyha;
    @apply thaana;
}

.text-typewriter {
    @apply font-typewriter;
    @apply thaana;
}

.text-roboto {
    @apply font-roboto;
    &-m {
        font-weight: 500;
    }
    &-b {
        font-weight: 700;
    }
}

.w-40px {
    width: 40px;
}
.h-40px {
    height: 40px;
}

.article-loading {
    animation-name: article-loading-animation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@keyframes article-loading-animation {
    0% {
        @apply opacity-25;
    }
    50% {
        @apply opacity-50;
    }
    100% {
        @apply opacity-25;
    }
}

@keyframes article-title-border-loading {
    0% {
        @apply border-white;
    }
    50% {
        @apply border-cloudy-blue;
    }
    100% {
        @apply border-white;
    }
}

@keyframes section-sub-titles-loading {
    0% {
        @apply border-whiteish;
    }
    50% {
        @apply border-cloudy-blue;
    }
    100% {
        @apply border-whiteish;
    }
}

.latest-trending-popular-titles {
    @apply mx-4 pb-2 border-b-2 border-white cursor-pointer;
    &.active {
        @apply border-cloudy-blue;
    }
    &.loading {
        animation-name: article-title-border-loading;
        animation-duration: 1s;
        animation-iteration-count: infinite;
    }
}

.sonee-latest-trending-popular-titles {
    @apply mx-4 pb-2 border-b-2 border-white cursor-pointer;
    &.active {
        @apply border-sonee-yellow;
    }
    &.loading {
        animation-name: article-title-border-loading;
        animation-duration: 1s;
        animation-iteration-count: infinite;
    }
}

.section-sub-titles {
    @apply py-3 border-b-2 border-whiteish cursor-pointer relative;
    // top: 2px;
    &.active {
        @apply border-warm-grey;
    }
    &.loading {
        animation-name: section-sub-titles-loading;
        animation-duration: 1s;
        animation-iteration-count: infinite;
    }
    &-main {
        &.active {
            @media (max-width: 768px) {
                @apply border-whiteish;
            }
        }
    }
}

.sports-section-sub-titles {
    @apply py-3 border-b-2 border-gray-500  cursor-pointer relative;
    // border-sonee-green
    // top: 2px;
    &.active {
        // @apply border-sonee-yellow;
    }
    &.loading {
        animation-name: section-sub-titles-loading;
        animation-duration: 1s;
        animation-iteration-count: infinite;
    }
    &-main {
        &.active {
            @media (max-width: 768px) {
                @apply border-whiteish;
            }
        }
    }
}

// .list-sports {
//     @apply border-mh-gray-65;
// }

/* purgecss start ignore */
.highlight {
    @apply bg-gray-200;
}

.section-world {
    .section-sub-titles {
        &.active {
            border-color: #dddb50;
        }
    }

    .highlight {
        background: #f6f6e3;
    }
}

// .section-sports {
//     .sports-section-sub-titles {
//         &.active {
//             // border-color: #ffc743;
//         }
//     }

//     p {
//         @apply text-cloudy-blue;
//     }

//     .highlight {
//         @apply bg-whiteish;
//     }

//     .highlight p {
//         color: #000;
//     }
// }

// LazyLoad FadeIn
.lazyload,
.lazyloading {
    opacity: 0;
}
.lazyloaded {
    opacity: 1;
    // transition: opacity 300ms;
}

.fslightbox-container {
    background: #000 !important;
}
/* purgecss end ignore */

.mirror-x {
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
}

.reaction {
    .color {
        opacity: 0;
        transition: 0.5s;
    }
    &:hover {
        .color {
            opacity: 1;
        }
    }
    &.active {
        .color {
            opacity: 1;
        }
    }
}

// .comment-reactions {
//     right: -20px;
//     bottom: 25px;
//     opacity: 0;
//     transition: 0.1s;
//     &.active {
//         opacity: 1;
//         bottom: 45px;
//     }
// }

.pagination {
    @apply flex justify-center;
    li {
        @apply flex justify-center text-14px h-30px text-white bg-pinkish-grey mx-1 px-2 py-1;
        min-width: 30px;
        &.active {
            @apply bg-denim;
        }
        a {
            outline: none;
        }
    }
}

.centered {
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    margin: auto;
}

.xs-menu {
    right: -400px;
    transition: 0.3s;
    &.active {
        right: 0;
    }
}

/* footer start */
.footer {
    a,
    .a:visited,
    a:active {
        text-decoration: none;
        @apply transition;
        @apply ease-out;
        @apply duration-200;
    }
    a:hover {
        text-decoration: underline;
    }
}
/* footer end */

.absolute {
    position: absolute;
}

.h-\[71px\] {
    height: 71px;
}

.w-full {
    width: 100%;
}

.w-\[71px\] {
    width: 71px;
}

.-translate-x-full {
    --tw-translate-x: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
        rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
        scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-full {
    --tw-translate-y: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
        rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
        scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.flex-col {
    flex-direction: column;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.overflow-hidden {
    overflow: hidden;
}

.rounded-xl {
    border-radius: 0.75rem;
}

.rounded-lg {
    border-radius: 0.5rem;
}

.rounded-md {
    border-radius: 0.375rem;
}

.border-transparent {
    border-color: transparent;
}

.bg-\[\#2D3748\] {
    --tw-bg-opacity: 1;
    background-color: rgb(45 55 72 / var(--tw-bg-opacity));
}

.bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-\[\#FB50A5\] {
    --tw-gradient-from: #fb50a5;
    --tw-gradient-to: rgb(251 80 165 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#00A8FC\] {
    --tw-gradient-from: #00a8fc;
    --tw-gradient-to: rgb(0 168 252 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-\[\#02B9D9\] {
    --tw-gradient-to: #02b9d9;
}

.to-\[\#00F992\] {
    --tw-gradient-to: #00f992;
}

.text-\[\#718096\] {
    --tw-text-opacity: 1;
    color: rgb(113 128 150 / var(--tw-text-opacity));
}

.text-\[\#CBD5E0\] {
    --tw-text-opacity: 1;
    color: rgb(203 213 224 / var(--tw-text-opacity));
}

.text-gray-200 {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity));
}

.shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.aspect-square {
    aspect-ratio: 1 / 1;
}

.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.duration-500 {
    transition-duration: 500ms;
}

.ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
        var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
        calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
        var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(234 179 8 / var(--tw-ring-opacity));
}

.focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
}

.group:hover .group-hover\:translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
        rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
        scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
        rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
        scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (min-width: 768px) {
    .md\:flex {
        display: flex;
    }

    .md\:hidden {
        display: none;
    }

    .md\:h-\[122px\] {
        height: 122px;
    }

    .md\:w-2\/12 {
        width: 16.666667%;
    }

    .md\:w-\[122px\] {
        width: 122px;
    }

    .md\:w-10\/12 {
        width: 83.333333%;
    }

    .md\:w-4\/6 {
        width: 66.666667%;
    }

    .md\:flex-row {
        flex-direction: row;
    }

    .md\:items-start {
        align-items: flex-start;
    }

    .md\:rounded-lg {
        border-radius: 0.5rem;
    }

    .md\:px-0 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .md\:py-12 {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .md\:pt-0 {
        padding-top: 0px;
    }

    .md\:pb-1 {
        padding-bottom: 0.25rem;
    }

    .md\:text-left {
        text-align: left;
    }
}

/* roadha gradient home page start */
.bg-roadha-gradient {
    background: rgb(0, 107, 101);
    background: radial-gradient(
        circle,
        rgba(0, 107, 101, 1) 0%,
        rgba(0, 56, 50, 1) 79%
    );
}
/* roadha gradient home page end */
