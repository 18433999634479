.splash-ad{
  color: #eee;
  display: none;
  &.active{
    display: block;
  }
  &-backdrop{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.85);
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1000;
  }
  &-image{
    padding: 15px;
    // width: 100%;
    &.portrait{
      // width: unset;
    }
    max-width: 1000px;
    &-container{
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      z-index: 1001;
    }
    &-creative{
      max-width: 100%;
      .advertisement{
        img{
          max-width: 100%;
          max-height: 80vh;
        }
      }
    }
  }
  input{
    display: inline;
    background-color: #cacaca;
    opacity: 0.9;
    margin-right: 3px;
    @media(max-width: 768px){
      position: relative;
      // top: 3px;
    }
  }
//   &-top{
//     display: flex;
//     justify-content: space-between;
//     @media(max-width: 768px){
//       justify-content: flex-end;
//     }
//     margin-bottom: 3px;
//     &-right{
//       display: inline-block;
//     }
//     &-left{
//       display: inline-block;
//     }
//   }

  &-close{
    font-size: 14px;
    font-weight: bold;
    color: #d9d9d9;
    cursor: pointer;

    svg {
      width: 20px;
      position: relative;
    }
  }

  &-text{
    &-adv{
      font-size: 12px;
      opacity: 0.5;
    }
    &-dnt-shw{
      font-size: 12px;
      margin-right: 20px;
      color: #d9d9d9;
    }
  }
}