/* purgecss start ignore */
.dho-features {
    background-color: #f3f5f5;

    .articles {

        .item-container {
            float: right;
            margin-top: 290px;
            min-height: 160px;
            .item {
                position: relative;
                .meta {
                    position: relative;
                    text-align: center;
                    position: relative;
                    top: 45px;
                    span {
                        text-transform: uppercase;
                        color: #7e8080;
                        font-size: 12px;
                        display: block;
                        margin: 15px 0px;
                    }
                    i {
                        width: 33px;
                        height: 33px;
                        border: solid 1px #fff;
                        border-radius: 50%;
                        display: block;
                        margin: 0 auto;
                        background-size: cover;
                    }
                }
                .thumb-container {
                    position: absolute;
                    bottom: 0;
                    vertical-align: bottom;
                    width: 100%;
                    text-align: center;
                    img {
                        max-height: 270px;
                        max-width: 100%;
                        margin: 0 auto;
                    }
                }
            }
            .title {
                color: #333;
                letter-spacing: 0.5px;
                margin-top: 10px;
                margin-bottom: 40px;
                font-size: 20px;
                line-height: 35px;
                font-weight: 400;
                text-align: center;
                a {
                    color: #333;
                    text-decoration: none;
                }
                .comment-bubble {
                    display: block;
                    background-color: #0a0d0d;
                    color: #fff;
                    font-size: 13px;
                    width: 26px;
                    height: 26px;
                    border-radius: 100%;
                    position: relative;
                    padding: 3px 0;
                    top: -23px;
                    line-height: 21px;
                    margin: 0 auto;
                    &:before {
                        content: " ";
                        display: block;
                        position: absolute;
                        width: 7px;
                        height: 7px;
                        background: #0a0d0d;
                        border: 2px solid #0a0d0d;
                        border-width: 0px 0px 3px 3px;
                        top: 18px;
                        left: 1px;
                    }
                }
            }
        }
    }
}


.slick-dots {
    @apply flex;
    @apply items-center;
    @apply justify-center;
}
.slick-dots li {
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 40px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;
    color: #DFE6EA;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
    color: #C0CED6;
}
/* purgecss end ignore */