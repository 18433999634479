

/* purgecss start ignore */
.ma-desktop {
    @apply hidden;
    @apply border border-gray-300;

    @screen xl {
        @apply block;
    }

}
.ma-tablet {
    @apply hidden;
    @apply border border-gray-300;

    @screen md {
        @apply block;
    }

    @screen xl {
        @apply hidden;
    }
    
}
.ma-mobile {
    @apply block;
    @apply border border-gray-300;

    @screen sm {
        @apply hidden;
    }
}

.adslot-paralax > .adslot > .maw {
    width: 100% !important;
    height: 597px !important;

    @screen md {
        height: 392px !important;
    }
}


.maw:before {
    content: 'Advertisement';
    position: absolute;
    margin-top: -17px;
    font-size: 10px;
    text-align: left !important;
    @apply text-gray-500;
}

.maw-sky {
    @apply bg-gray-100;
    width: 335px;
    height: auto;
    max-height: 162px;

    @screen sm {
        width: 100%;
        max-width: 970px;
        max-height: 177px;
    }

    @screen sm {
        .ma-mobile {
            display: none !important;
        }

        .ma-tablet {
            display: none !important;
        }

        .ma-desktop {
            display: block !important;
        }
    }
}

.maw-sky-sports {
    @apply bg-white-three;
    width: 100%;
    height: auto;
    max-height: 162px;

    @screen sm {
        width: 100%;
        max-height: 376px;
    }

    @screen sm {
        .ma-mobile {
            display: none !important;
        }

        .ma-tablet {
            display: none !important;
        }

        .ma-desktop {
            display: block !important;
        }
    }
}

.maw-home2 {
    @apply bg-gray-100;
    width: 335px;
    height: auto;
    max-height: 335px;

    @screen lg {
        width: 231px;
        max-height: 394px;

        // .ma-mobile {
        //     display: none !important;
        // }
    }

    @screen xl {
        width: 270px;
        max-height: 394px;

        // .ma-mobile {
        //     display: none !important;
        // }
    }

    @screen sm {
        .ma-mobile {
            display: block !important;
        }

        .ma-tablet {
            display: none !important;
        }

        .ma-desktop {
            display: none !important;
        }
    }

    @screen lg {
        .ma-mobile {
            display: none !important;
        }

        .ma-tablet {
            display: none !important;
        }

        .ma-desktop {
            display: block !important;
        }
    }
}
.maw-home3, 
.maw-home4, 
.maw-home5 {
    @apply bg-gray-100;
    width: 335px;
    height: auto;
    max-height: 335px;

    @screen sm {
        width: 100%;
        max-width: 970px;
        max-height: 177px;
    }

    @screen sm {
        .ma-mobile {
            display: none !important;
        }

        .ma-tablet {
            display: none !important;
        }

        .ma-desktop {
            display: block !important;
        }
    }
}
.maw-home4 {
    @apply bg-gray-100;
}
.maw-home5 {
    @apply bg-gray-100;
}
.maw-home7 {
    @apply bg-blackish border-none;
    width: 350px;
    height: auto;
    max-height: 350px;

    @screen sm {
        width: 100%;
        max-width: 970px;
        max-height: 300px;
    }

    @screen sm {
        .ma-mobile {
            display: none !important;
        }

        .ma-tablet {
            display: none !important;
        }

        .ma-desktop {
            display: block !important;
        }
    }
}

.maw-detail2 {
    @apply bg-gray-100;
    width: 335px;
    height: auto;
    max-height: 335px;

    @screen xl {
        width: 370px;
        max-height: 500px;
    }
}
.maw-detail3 {
    @apply bg-gray-100;
    width: 335px;
    height: auto;
    max-height: 335px;

    @screen xl {
        width: 370px;
        max-height: 370px;
    }
}
.maw-detail4 {
    @apply bg-gray-100;
    width: 335px;
    height: auto;
    max-height: 335px;

    @screen xl {
        width: 770px;
        max-height: 308px;
    }
}
.maw-detail5 {
    @apply bg-gray-100;
    width: 335px;
    height: auto;
    max-height: 335px;

    @screen xl {
        width: 570px;
        max-height: 350px;
    }
}
.maw-detail6 {
    @apply bg-gray-100;
    width: 335px;
    height: 335px;

    @screen xl {
        width: 370px;
        height: 500px;
    }
}

.maw-section2 {
    @apply bg-gray-100;
    width: 335px;
    height: 335px;

    @screen xl {
        width: 370px;
        height: 370px;
    }
}

.maw-section3 {
    @apply bg-gray-100;
    width: 100% !important;
    height: 597px !important;

    @screen md {
        height: 392px !important;
    }
}
.maw-sport-section3 {
    @apply bg-gray-100;
    width: 100% !important;
    min-height: 540px !important;

    @screen md {
        height: 500px !important;
    }
}
/* purgecss end ignore */