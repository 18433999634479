.col4-list-dashed div{
  // xs
  @media(max-width: 639px){
    border-left: none!important;
    &:nth-child(1){
      border-top: none!important;
    }
  }
  // sm
  @media(min-width: 640px) and (max-width: 1023px){
    &:nth-child(1){
      border-top: none!important;
    }
    &:nth-child(2){
      border-top: none!important;
    }
    &:nth-child(2n){
      border-left: none!important;
    }
  }
  // md
  @media(min-width: 1024px){

    &:nth-child(1){
      border-top: none!important;
    }
    &:nth-child(2){
      border-top: none!important;
    }
    &:nth-child(3){
      border-top: none!important;
    }
    &:nth-child(4){
      border-top: none!important;
    }
    &:nth-child(4n){
      border-left: none!important;
    }

  }
}

.gallery-list-dashed div{
  // xs
  @media(max-width: 639px){
    border-left: none!important;
    &:nth-child(1){
      border-top: none!important;
    }
  }
  // sm
  @media(min-width: 640px) and (max-width: 1023px){
    &:nth-child(1){
      border-top: none!important;
    }
    &:nth-child(2){
      border-top: none!important;
    }
    &:nth-child(2n){
      border-left: none!important;
    }
  }
  // md
  @media(min-width: 1024px){

    &:nth-child(1){
      border-top: none!important;
    }
    &:nth-child(2){
      border-top: none!important;
    }
    &:nth-child(3){
      border-top: none!important;
    }
    &:nth-child(4){
      border-top: none!important;
    }
    &:nth-child(4n){
      border-left: none!important;
    }

  }
}

.latest-popular-seven-days div{
  // xs
  @media(max-width: 639px){
    border-left: none!important;
    &:nth-child(1){
      border-top: none!important;
    }
  }
  // sm
  @media(min-width: 640px) and(max-width: 1279px){
    &:nth-child(1){
      border-top: none!important;
    }
    &:nth-child(2){
      border-top: none!important;
    }
    &:nth-child(2n){
      border-left: none!important;
    }
  }
  // md
  @media(min-width: 1280px){
    &:nth-child(1){
      border-top: none!important;
    }
    &:nth-child(2){
      border-top: none!important;
    }
    &:nth-child(3){
      border-top: none!important;
    }
    &:nth-child(3n){
      border-left: none!important;
    }
  }
}

.sections-list-dashed div{
  &:nth-child(1){
    border-top: none!important;
  }
  &:nth-child(2){
    border-top: none!important;
  }
  &:nth-child(3){
    border-top: none!important;
  }
  &:nth-child(4){
    border-top: none!important;
  }
  &:nth-child(4n){
    border-right: none!important;
  }
}

.col2-list-dashed div{
  // xs
  @media(max-width: 639px){
    &:nth-child(1){
      border-top: none!important;
    }
  }
  // sm
  @media(min-width: 640px){
    &:nth-child(1){
      border-top: none!important;
    }
    &:nth-child(2){
      border-top: none!important;
    }
    &:nth-child(2n){
      border-right: none!important;
    }
  }
}

.col1-list-dashed div{
  &:nth-child(1){
    border-top: none!important;
  }
}

.menu-list-dashed div{
  div div:first-of-type{
    @apply pt-0 border-t-0;
  }
  &:nth-child(4){
    div{
      border-right: none;
      padding-right: none;  
    }
  }
}